<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                携手国泰君安证券，探讨交易大时代下的创新与共赢
              </p>
              <div class="fst-italic mb-2">2024年5月27日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >国泰君安证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/81/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    5月17日，国泰君安证券与非凸科技联合举办了“科技赋能投资”系列主题活动，与行业人士共同探讨如何实现科技创新与金融服务的深度融合，以及可持续发展与生态伙伴的合作共赢。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/81/02-张望.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近年来，国泰君安证券始终坚持“金融报国”理念，坚定不移走中国特色金融发展之路，深入探索前沿技术在金融领域的应用和场景落地，以更加开放、创新的姿态为行业高质量发展贡献更多智慧和力量。活动上，国泰君安证券陆家嘴机构部经理张望在致辞中表示，此次活动不仅是业务交流与合作的机会，更是携手应对未来挑战的重要契机。国泰君安证券将充分发挥综合服务平台优势，与合作伙伴资源互补、贯通生态，携手为广大投资者带来更好的交易服务体验。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/81/03-乌仁其木格.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    国泰君安证券私人客户部乌仁其木格就“资产配置：择良木而栖”主题进行了分享。她表示，当前市场环境复杂多变，投资诉求日益多元化。国泰君安证券直面客户投资过程中的核心痛点，聚焦客户财富保值增值服务需求，提供一站式、定制化、专业化、陪伴式、有温度的综合金融解决方案。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/81/04-李翔.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    随后，国泰君安证券上海分公司机构业务助理总监李翔深入分享了FOF产品的配置要求与产品设计，强调了选取合适的管理人在业绩表现中的重要性。他又进一步分析了在股票权益市场冲击下，需要选择什么样的主观股票多头管理人，而在量化策略出现风险事件后，又需要选择什么样的量化中性或指数增强管理人。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/81/05-周升.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    非凸科技机构业务经理周升针对智能算法、交易终端以及合作模式等方面进行了详细介绍。他表示，开放生态、合作共赢是非凸科技一直以来面向行业的态度。在科技创新道路上，很高兴能与行业伙伴通力合作、优势互补，并得到极大认可与支持。非凸科技始终以客户需求为导向，将协同国泰君安证券等合作伙伴积极发挥科技创新优势，为客户提供优质的产品解决方案与技术服务，构建完整的金融科技服务生态。
                  </p>
                  <p>
                    交流环节，管理人与资方积极参与，各自介绍了在多资产、单策略、衍生品配置及家族信托等方面的服务，还就市场热点、技术创新、算法策略等方面展开了深入探讨。
                  </p>
                  <p>
                    未来，非凸科技仍将聚焦产品的创新与升级，为金融机构提供强有力的技术支撑和前沿的科技创新，助力其业务高效开展。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News81",
};
</script>

<style></style>
